import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Typography, Space, Button, notification } from 'antd'; // Import notification
import { FaTwitter, FaTelegram, FaGlobe } from 'react-icons/fa';
import Banner from './Banner'; // Import the Banner component
import { FaLink } from 'react-icons/fa'; // GMGN Link icon (can be used in place of gmgn.png)

const { Text, Title } = Typography;

function PaidTokens() {
    const [paidTokens, setPaidTokens] = useState([]);
    const [isPolling, setIsPolling] = useState(true); // State to control polling
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`${baseURL}/api/paid-tokens`)
                .then((response) => {
                    const tokens = response.data.tokens || [];

                    // Sort tokens by timestamp in descending order (newest first)
                    const sortedTokens = tokens.sort((a, b) => b.timestamp - a.timestamp);

                    // Set the sorted tokens
                    setPaidTokens(sortedTokens);
                })
                .catch((error) => {
                    console.error('Error fetching paid tokens:', error.message);
                });
        }, 1000); // Poll every 1 second

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    // Function to calculate time difference in minutes
    const timeAgo = (timestamp) => {
        const now = new Date();
        const diffInMs = now - new Date(timestamp); // Difference in milliseconds
        const diffInMinutes = Math.floor(diffInMs / 1000 / 60); // Convert to minutes
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            notification.success({
                message: 'Copied to Clipboard',
                description: `Mint address ${text} has been copied to your clipboard.`,
            });
        }).catch((error) => {
            notification.error({
                message: 'Copy Failed',
                description: `Failed to copy mint address: ${error.message}`,
            });
        });
    };
    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text, token) => {
                let imageUrl = token.image;

                // Check if the image URL starts with 'https://ipfs.io/'
                if (imageUrl && imageUrl.startsWith('https://ipfs.io/')) {
                    // Replace 'https://ipfs.io/' with 'https://pump.pinata.cloud/'
                    imageUrl = imageUrl.replace('https://ipfs.io/', 'https://pump.mypinata.cloud/');
                }

                return imageUrl ? (
                    <img
                        src={imageUrl + '?img-width=200&img-dpr=1&img-onerror=redirect'}
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                    />
                ) : (
                    <Text>No image</Text>
                );
            },
        },
        {
            title: 'Token Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Text strong>{text}</Text>,
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (text) => <Text type="secondary">{text}</Text>,
        },
        {
            title: 'Mint Address',
            dataIndex: 'mintAddress',
            key: 'mintAddress',
            render: (text) => (
                <Text
                    type="secondary"
                    style={{ cursor: 'pointer', color: '#1890ff' }}
                    onClick={() => handleCopyToClipboard(text)}
                >
                    {text}
                </Text>
            ),
        },
        {
            title: 'Detected Time',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp) => <Text>{timeAgo(timestamp)}</Text>, // Display time ago in minutes
        },
        {
            title: 'Links',
            key: 'links',
            render: (text, token) => (
                <Space>
                    {token.twitter && (
                        <a href={token.twitter} target="_blank" rel="noopener noreferrer">
                            <FaTwitter style={{ fontSize: '24px', color: '#1DA1F2' }} />
                        </a>
                    )}
                    {token.website && (
                        <a href={token.website} target="_blank" rel="noopener noreferrer">
                            <FaGlobe style={{ fontSize: '24px', color: '#666' }} />
                        </a>
                    )}
                    {token.telegram && (
                        <a href={token.telegram} target="_blank" rel="noopener noreferrer">
                            <FaTelegram style={{ fontSize: '24px', color: '#0088cc' }} />
                        </a>
                    )}
                    {/* Add GMGN link */}
                    <a
                        href={`https://gmgn.ai/sol/token/${token.mintAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="/gmgn.png" // Add your gmgn logo image here
                            alt="GMGN"
                            style={{ width: 25, height: 25, objectFit: 'cover' }}
                        />
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Banner />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>

                <Title level={2} style={{ textAlign: 'center', margin: 0 }}>
                    DEX Screener Paid Tokens
                </Title>

                <Space />
            </div>

            {paidTokens.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={paidTokens}
                    rowKey="mintAddress"
                    pagination={false}
                    bordered
                />
            ) : (
                // image public/loading-gif.gif
                <img src="loading-gif.gif" alt="Loading..." style={{ width: '2%', height: '2%' }} />
            )}
        </div>
    );
}

export default PaidTokens;


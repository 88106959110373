import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Upload, Button, Radio, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Buffer } from "buffer";
import {
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  Transaction,
  SystemProgram,
} from "@solana/web3.js";

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const Banner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [walletAddress, setWalletAddress] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [banners, setBanners] = useState([]); // State to hold the banners
  const [randomBanner, setRandomBanner] = useState(null); // State for the random banner
  const baseURL = process.env.REACT_APP_BASE_URL;

  const pricePackages = [
    { price: 0.2, days: 1 },
    { price: 0.4, days: 2 },
    { price: 0.5, days: 3 },
  ];

  // Fetch active banners from the backend
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${baseURL}/api/active-banners`);
        const data = await response.json();
        setBanners(data.banners);

        // Select a random banner from the fetched banners
        if (data.banners && data.banners.length > 0) {
          const randomIndex = Math.floor(Math.random() * data.banners.length);
          setRandomBanner(data.banners[randomIndex]);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, []);

  const connectWallet = async () => {
    if (!window.solana) {
      notification.error({
        message:
          "Solana wallet not found! Please install Phantom or a compatible wallet.",
      });
      return;
    }

    try {
      const response = await window.solana.connect();
      setWalletAddress(response.publicKey.toString());
      notification.success({ message: "Wallet connected successfully!" });
    } catch (error) {
      console.error("Wallet connection error:", error);
      notification.error({ message: "Failed to connect wallet." });
    }
  };

  const handlePaymentAndSubmit = async (values) => {
    if (!walletAddress) {
      notification.error({ message: "Please connect your wallet first." });
      return;
    }

    if (!selectedPackage) {
      notification.error({ message: "Please select a package." });
      return;
    }

    const { link, banner } = values;

    // Use QuickNode RPC provider for better reliability
    const connection = new Connection(
      "https://powerful-proud-silence.solana-mainnet.quiknode.pro/f74c3a143641a46c7fa6beea687f7f472831289a",
      "confirmed"
    );
    const advertiserPublicKey = new PublicKey(
      "DrxGv4Ppgars6LBDBLW5pEB4Gx8s1buwH5KNAS6LmD45"
    );
    const amount = selectedPackage.price * LAMPORTS_PER_SOL;

    try {
      setLoading(true);

      // Get the latest blockhash
      const { blockhash } = await connection.getLatestBlockhash();

      // Create the transaction
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: new PublicKey(walletAddress),
          toPubkey: advertiserPublicKey,
          lamports: amount,
        })
      );

      // Set blockhash and fee payer
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = new PublicKey(walletAddress);

      // Sign the transaction
      const signedTransaction = await window.solana.signTransaction(
        transaction
      );

      // Send the transaction
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );

      // Wait for the confirmation of the transaction
      const confirmation = await connection.confirmTransaction(signature);

      if (confirmation.value.err) {
        throw new Error("Transaction failed");
      }

      setTransactionId(signature);
      notification.success({
        message: `Payment successful! Transaction ID: ${signature}`,
      });

      const formData = new FormData();
      formData.append("link", link);
      formData.append("walletAddress", walletAddress);
      formData.append("package", JSON.stringify(selectedPackage));
      formData.append("transactionId", signature);
      formData.append("image", banner.originFileObj); // Add the file itself

      // Submit the form data to the backend
      await fetch(`${baseURL}/api/submit-banner`, {
        method: "POST",
        body: formData,
      });

      notification.success({ message: "Banner submission successful!" });
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Payment error:", error);
      notification.error({ message: `Payment failed: ${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Render the random banner */}
      {randomBanner && (
        <div
          style={{
            width: "500px",
            height: "200px", // Adjust height as needed
            margin: "20px auto", // Center the banner
            backgroundImage: `url(/banners/${randomBanner.image})`,
            backgroundSize: "contain", // Adjust background size to contain the image within the container
            backgroundPosition: "center",
            borderRadius: "8px",
            cursor: "pointer", // Change cursor to pointer for clickable banner
            display: "flex", // Add display flex to enable flexbox properties
            justifyContent: "center", // Center the image horizontally
            alignItems: "center", // Center the image vertically
          }}
          onClick={() => window.open(randomBanner.link, "_blank")}
        >
          <img
            src={`${baseURL}` + `/api` + randomBanner.image}
            alt="logo"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      )}

      <div style={{ textAlign: "center", marginTop: "-25px", marginBottom: "15px" }}>
        <a href="#" onClick={() => setIsModalVisible(true)}>
          Advertise your banner here
        </a>
      </div>


      <Modal
        title="Advertise Your Banner"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handlePaymentAndSubmit}>
          <Form.Item
            label="Link"
            name="link"
            rules={[
              {
                required: true,
                message: "Please provide the link for your banner.",
              },

              {
                pattern: /^https:\/\//,
                message: "Please enter a valid URL starting with https://",
              },
            ]}
          >
            <Input placeholder="Enter your Website link or DEXScreener"/>
          </Form.Item>
          <Form.Item
            label="Banner Image"
            name="banner"
            valuePropName="file"
            getValueFromEvent={(e) => e.fileList[0]}
            rules={[
              { required: true, message: "Please upload your banner image." },
            ]}
          >
            <Upload beforeUpload={() => false} accept="image/png" maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload PNG</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Select Package" required>
            <Radio.Group onChange={(e) => setSelectedPackage(e.target.value)}>
              {pricePackages.map((pkg, index) => (
                <Radio key={index} value={pkg}>
                  {pkg.price} SOL for {pkg.days} day{pkg.days > 1 ? "s" : ""}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Button
            type="primary"
            onClick={connectWallet}
            style={{ marginBottom: "10px", width: "100%" }}
          >
            {walletAddress ? `Connected: ${walletAddress}` : "Connect Wallet"}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Submit and Pay {selectedPackage?.price || "N/A"} SOL
          </Button>

          {transactionId && (
            <div style={{ marginTop: "10px" }}>
              <strong>Transaction ID:</strong> {transactionId}
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Banner;

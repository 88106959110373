import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button, Typography, Spin, Alert } from 'antd';
import Banner from './Banner';
const { Title } = Typography;

const CheckDexPaid = () => {
  const [contractAddress, setContractAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null); // Keeps track of the status type
  const [error, setError] = useState(null);

  const fetchDexStatus = async () => {
    setLoading(true);
    setError(null);
    setStatus(null);
    setStatusType(null);

    try {
      // Make the API call to Dexscreener
      const response = await axios.get(`https://api.dexscreener.com/orders/v1/solana/${contractAddress}`);
      
      // Extract data and determine the paid status
      const data = response.data;
      
      // Check if the response is an array and contains the relevant information
      if (Array.isArray(data) && data.length > 0) {
        const tokenProfile = data.find(item => item.type === 'tokenProfile');
        
        if (tokenProfile) {
          const { status } = tokenProfile;

          // Check if the status is 'approved' or 'pending'
          if (status === 'approved' || status === 'pending') {
            setStatus('DEX Screener Paid');
            setStatusType('success');
          } else {
            setStatus('Not paid yet.');
            setStatusType('info');
          }
        } else {
          setStatus('No token profile found.');
          setStatusType('warning');
        }
      } else {
        setStatus('No data found for this contract address.');
        setStatusType('warning');
      }
    } catch (err) {
      setError('Failed to fetch status. Please check the contract address and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Banner />
      <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>
        Check Dex Paid Status
      </Title>

      <Input
        placeholder="Enter Solana Contract Address"
        value={contractAddress}
        onChange={(e) => setContractAddress(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      <Button
        type="primary"
        onClick={fetchDexStatus}
        disabled={!contractAddress}
        loading={loading}
        style={{ width: '100%' }}
      >
        Check Status
      </Button>

      <div style={{ marginTop: '20px' }}>
        {loading && <Spin tip="Fetching data..." />}
        {error && <Alert message={error} type="error" showIcon />}
        {status && (
          <Alert
            message={status}
            type={statusType} // Dynamically sets the type: success, info, or warning
            showIcon
          />
        )}
      </div>
    </div>
  );
};

export default CheckDexPaid;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout, ConfigProvider, theme, Switch, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import PaidTokens from "./components/PaidTokens";
import NewPumpTokens from "./components/NewPumpTokens";
import CheckDexPaid from "./components/CheckDexPaid";
import PumpSniper from "./components/PumpSniper";
import RaySniper from "./components/RaySniper";
import NotFound from "./components/NotFound";
import FooterComponent from './components/FooterComponent.js';
import Imageboard from "./components/Imageboard";
import ThreadPage from './components/ThreadPage';



const { Content } = Layout;
const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const savedDarkMode = localStorage.getItem("isDarkMode");
  const [isDarkMode, setIsDarkMode] = useState(savedDarkMode !== null ? savedDarkMode === "true" : true); // Set default to true if not found in storage
  const [isSidebarVisible, setIsSidebarVisible] = useState(true); // State to toggle sidebar visibility
  const [walletAddress, setWalletAddress] = useState(null); // Wallet address state

  const handleConnectWallet = (address) => {
    setWalletAddress(address); // Update wallet address
    sessionStorage.setItem("walletAddress", address); // Store wallet address in session storage
    console.log("Connected with " + address);
  };


  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem("isDarkMode", newTheme);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible); // Toggle sidebar visibility
  };

  useEffect(() => {
    document.body.setAttribute("data-theme", isDarkMode ? "dark" : "light");
    localStorage.setItem("isDarkMode", isDarkMode); // Save the dark mode setting to storage
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorBgLayout: isDarkMode ? "#141414" : "#fff",
          colorPrimary: isDarkMode ? "#fa8c16" : "#1677ff",
        },
      }}
    >
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <Header walletAddress={walletAddress} onConnect={handleConnectWallet} style={{ width: "100%" }} />
          <Layout>
            {/* Sidebar Toggle Button */}
            
            {/* Conditional Rendering of Sidebar */}
            {isSidebarVisible && (
              <Sidebar theme={isDarkMode ? "dark" : "light"} />
            )}
            <Button
              type="text"
              onClick={toggleSidebar}
              style={{

                top: 20,
                left: 16, // Fixed position on the left
                zIndex: 1001,
                padding: "10px 20px", // Add padding for a larger clickable area
                background: isDarkMode ? "#1f1f1f" : "#fff", // Match theme
                color: isDarkMode ? "#fa8c16" : "#1677ff", // Match theme colors
                borderRadius: "8px", // Slightly rounded corners
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)", // Add shadow for emphasis
                display: "flex",
                flexDirection: "column", // Stack icon and text
                alignItems: "center",
                justifyContent: "center",
                width: 80, // Make the button wider
              }}
            >
              {isSidebarVisible ? (
                <MenuFoldOutlined
                  style={{ fontSize: "20px", marginBottom: "4px" }}
                /> // Icon with margin
              ) : (
                <MenuUnfoldOutlined
                  style={{ fontSize: "20px", marginBottom: "4px" }}
                />
              )}
            </Button>
            <Layout
              style={{
                marginLeft: isSidebarVisible ? 50 : 0,
                transition: "margin-left 0.2s",
                width: "100%", // Add this line
                maxWidth: "100%", // Add this line
                "@media (max-width: 768px)": { // Add this media query
                  marginLeft: 0,
                  width: "100%",
                  maxWidth: "100%",
                },
              }}
            >
              
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                  background: isDarkMode ? "#1f1f1f" : "#fff",
                  overflow: "auto",
                }}
              >
                
                <Routes>
                  <Route path="/" element={<PaidTokens />} />
                  <Route path="/paid-tokens" element={<PaidTokens />} />
                  <Route path="/new-pump-tokens" element={<NewPumpTokens />} />
                  <Route path="/check-dex-paid" element={<CheckDexPaid />} />
                  <Route path="/pumpfun-sniper-tool" element={<PumpSniper />} />
                  <Route path="/raydium-sniper-tool" element={<RaySniper />} />
                  <Route path="/imageboard" element={<Imageboard walletAddress={walletAddress}/>} />
                  <Route path="/thread/:id" element={<ThreadPage walletAddress={walletAddress}/>} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Content>
            </Layout>
          </Layout>
          <div
            style={{
              position: "fixed",
              bottom: 5,
              right: 20,
              zIndex: 1000,
              background: isDarkMode ? "#1f1f1f" : "#fff",
              borderRadius: "50%",
              padding: "10px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Switch
              checked={isDarkMode}
              onChange={toggleTheme}
              checkedChildren="Dark"
              unCheckedChildren="Light"
            />
          </div>
          <FooterComponent/>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

export default App;

import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";

const { Sider } = Layout;

const Sidebar = () => {
  return (
    <Sider>
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item key="1">
          <Link to="/imageboard">Imageboard</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/paid-tokens">Paid Tokens</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/new-pump-tokens">New Pump Tokens</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/check-dex-paid">Check DEX Paid</Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/pumpfun-sniper-tool">PF Sniper Bot 🌶️ </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/raydium-sniper-tool">Raydium Sniper Bot 🌶️ </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;

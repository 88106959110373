import React, { useState } from 'react';
import { Layout, Button, message } from 'antd';
import { FaTelegram } from 'react-icons/fa';
import { Connection, PublicKey } from '@solana/web3.js';

const { Header } = Layout;

// IDP Token Address on Solana (replace this with the actual token address)
const IDP_TOKEN_MINT_ADDRESS = new PublicKey('9TmQyKvkAW9Yi3a9pR3jj8GNrtu574mo7GZzGdxBpump');
const connection = new Connection("https://powerful-proud-silence.solana-mainnet.quiknode.pro/f74c3a143641a46c7fa6beea687f7f472831289a", 'confirmed'); // Connection to Solana
const baseURL = process.env.REACT_APP_BASE_URL

function HeaderComponent({ walletAddress, onConnect }) {
  const [tokenBalance, setTokenBalance] = useState(0); // State to store the user's IDP token balance


  async function getTokenBalance(walletAddress, tokenMintAddress) {
    try {
      // Convert wallet address to PublicKey
      const publicKey = new PublicKey(walletAddress);

      // Fetch the associated token accounts of the wallet
      const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
        mint: tokenMintAddress
      });

      // If no token accounts found, return 0 balance
      if (tokenAccounts.value.length === 0) {
        return 0;
      }

      // Fetch the balance of the first token account
      const tokenAccount = tokenAccounts.value[0].pubkey;
      const accountInfo = await connection.getTokenAccountBalance(tokenAccount);

      // Return the token balance (in UI format, e.g., integer value)
      return accountInfo.value.uiAmount || 0;
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return 0 in case of error
    }
  }
  const sendBalanceToBackend = async (walletAddress, balance) => {
    try {
      const response = await fetch(`${baseURL}` + `/api/update-balance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress, balance }),
      });
  
      if (response.ok) {
        console.log("Balance successfully sent to backend");
      } else {
        console.error("Error sending balance to backend");
      }
    } catch (error) {
      console.error("Error during balance POST request:", error);
    }
  };
  const connectWallet = async () => {
    if (!window.solana) {
      message.error('Solana wallet not found! Please install Phantom or a compatible wallet.');
      return;
    }
    try {
      const response = await window.solana.connect();
      onConnect(response.publicKey.toString()); // Pass the wallet address back to App.js
      message.success(`Connected with wallet: ${response.publicKey.toString()}`);

      // Fetch token balance once wallet is connected
      getTokenBalance(response.publicKey.toString(), IDP_TOKEN_MINT_ADDRESS).then(balance => {
        console.log("Token Balance: ", balance);
        // Update the token balance state and make full number only
        setTokenBalance(Number(balance.toFixed(0)));
        sendBalanceToBackend(response.publicKey.toString(), balance);
        
      }).catch(error => {
        console.error("Error: ", error);
      });
    } catch (error) {
      console.error('Wallet connection error:', error);
      message.error('Failed to connect wallet.');
    }
  };

  return (
    <Header
      style={{
        background: 'orange',
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {/* Left Content */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src="/logo-transparent.png" // Assuming the image is in the public folder
          alt="isdexpaid logo"
          style={{
            width: '50px',
            height: '50px',
            marginRight: '10px',
            objectFit: 'contain',
          }}
        />
        <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
          isdexpaid?
        </div>
      </div>

      {/* Right Content */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Telegram Link */}
        <a
          href="https://t.me/isdexpaidxyz"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white', fontSize: '24px', marginRight: '20px' }}
        >
          <FaTelegram />
        </a>

        {/* Solana Wallet Connect Button */}
        <Button
          type="primary"
          onClick={connectWallet}
          style={{ marginLeft: '10px', backgroundColor: '#fa8c16' }}
        >
          {walletAddress ? `Connected: ${walletAddress.slice(0, 6)}...` : 'Connect Wallet'}
        </Button>

        {/* Display the IDP token balance */}
        {walletAddress && (
          <div style={{ color: 'white', marginLeft: '20px' }}>
            IDP Balance: {tokenBalance}
          </div>
        )}
      </div>
    </Header>
  );
}


export default HeaderComponent;

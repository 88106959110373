import React, { useState, useEffect } from "react";
import { List, Card, Modal, Form, Input, Button, Upload, message } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import Banner from './Banner';

const baseURL = process.env.REACT_APP_BASE_URL;
const appURL = process.env.APP_URL;
const { TextArea } = Input;

const Imageboard = () => {
  const [threads, setThreads] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const walletAddress = sessionStorage.getItem("walletAddress"); // Retrieve wallet address from session storage

  useEffect(() => {
    
    fetchThreads();
  }, []);

  const fetchThreads = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/threads`);
      setThreads(response.data); // Threads include idpCrownHolder flag
    } catch (error) {
      console.error("Error fetching threads:", error);
      message.error("Failed to fetch threads");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("content", values.content);
      if (values.image && values.image[0]) {
        formData.append("image", values.image[0].originFileObj);
      }
      formData.append("creatorId", walletAddress); // Add the wallet address to the form data
      console.log("Submitting thread with the following data:", {
        title: values.title,
        content: values.content,
        image: values.image ? values.image[0]?.originFileObj : null,
        creatorId: walletAddress,
      });
      await axios.post(`${baseURL}/api/threads`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Thread created successfully");
      setIsModalVisible(false);
      form.resetFields();
      fetchThreads();
    } catch (error) {
      console.error("Error creating thread:", error);
      message.error("Failed to create thread");
    }
  };

  // Render the creator ID with a crown if idpCrownHolder is true
  const renderCreatorId = (creatorId, isCrownHolder) => (
    <span>
      {creatorId}
      {isCrownHolder && (
        <span>👑</span>
      )}
    </span>
  );

  return (
    <div>
      <Banner />
      {walletAddress && (
      <Button
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{ marginBottom: 16 }}
      >
        New Thread
      </Button>
      )}
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={threads}
        renderItem={(thread) => (
          <List.Item>
            <Link to={`/thread/${thread._id}`}>
              <Card
                size="small"
                hoverable
                style={{ maxWidth: "500px" }}
                cover={
                  thread.image && (
                    <img
                      alt="Thread"
                      src={`${baseURL}/api${thread.image}`}
                      style={{ objectFit: "cover", padding: "4px" }}
                    />
                  )
                }
                title={thread.title}
              >
                {/* Display creatorId with crown */}
                <p>Creator: {renderCreatorId(thread.creatorId, thread.idpCrownHolder)}</p>
                <p>{thread.content.substring(0, 100)}...</p>
                <p>Replies: {thread.replies.length}</p>
              </Card>
            </Link>
          </List.Item>
        )}
      />
      <Modal
        title="Create New Thread"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item
            name="content"
            rules={[{ required: true, message: "Please input the content!" }]}
          >
            <TextArea rows={4} placeholder="Content" />
          </Form.Item>
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
          >
            <Upload
              beforeUpload={(file) => {
                const isValidType =
                  file.type === "image/png" ||
                  file.type === "image/jpeg" ||
                  file.type === "image/gif";
                if (!isValidType) {
                  message.error("You can only upload PNG, JPEG, or GIF files!");
                }
                return isValidType || Upload.LIST_IGNORE; // Ignore the file if type is invalid
              }}
              maxCount={1} // Allow only one file
              onChange={({ fileList }) => {
                // Manually update the form value with the selected file
                form.setFieldsValue({ image: fileList });
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Thread
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Imageboard;

import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

function FooterComponent() {
    return (
        <Footer
            style={{
                textAlign: 'center',
                color: '#ffffff',
                padding: '20px',
                fontSize: '14px',
                position: 'relative',
                bottom: '0',
                width: '100%',
            }}
        >
            <div>
                &copy; {new Date().getFullYear()} isdexpaid? $IDP: <strong>9TmQyKvkAW9Yi3a9pR3jj8GNrtu574mo7GZzGdxBpump</strong>
            </div>
        </Footer>
    );
}

export default FooterComponent;

import React, { useState } from 'react';
import { Input, Button, Typography, Spin, Alert, Card, Modal } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const PumpSniper = () => {
  const [ devWalletAddress ] = useState('');
  const [ prioFee ] = useState('');
  const [ solAmount ] = useState('');
  const [ contractAddress ] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(true); // Controls the "Stay tuned" modal

  const fetchDexStatus = () => {
    setLoading(true);
    setError(null);
    setStatus(null);
    setStatusType(null);
    // Placeholder for API logic
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '600px',
        margin: '0 auto',
        filter: showModal ? 'blur(5px)' : 'none', // Blur effect when modal is displayed
        transition: 'filter 0.3s ease',
      }}
    >
      <Card
        bordered={false}
        style={{
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{

            padding: '20px',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <ThunderboltOutlined style={{ fontSize: '40px', color: '#FFD700' }} />
          <Title level={3} style={{ margin: '10px 0', color: '#FFD700' }}>
            PumpFun Sniper Bot
          </Title>
          <Text style={{ color: '#fff' }}>
            Snipe new tokens instantly and stay ahead in the crypto game.
          </Text>
        </div>
        <div style={{ padding: '20px' }}>
          <Input
            placeholder="Enter PF Creator Dev Wallet"
            value={devWalletAddress}
            //onChange={(e) => setContractAddress(e.target.value)}
            style={{
              marginBottom: '20px',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '16px',
            }}
          />
          <Input
            placeholder="Enter Sol Amount"
            value={solAmount}
            //onChange={(e) => setContractAddress(e.target.value)}
            style={{
              marginBottom: '20px',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '16px',
            }}
          />
          <Input
            placeholder="Enter Priority Fee"
            value={prioFee}
            //onChange={(e) => setContractAddress(e.target.value)}
            style={{
              marginBottom: '20px',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '16px',
            }}
          />
          <Button
            type="primary"
            onClick={fetchDexStatus}
            disabled={!solAmount}
            loading={loading}
            style={{
              width: '100%',

              borderColor: '#001529',
              height: '50px',
              fontSize: '16px',
            }}
          >
            Snipe
          </Button>

          <div style={{ marginTop: '20px' }}>
            {loading && <Spin tip="Fetching data..." />}
            {error && <Alert message={error} type="error" showIcon />}
            {status && (
              <Alert
                message={status}
                type={statusType}
                showIcon
                style={{ marginTop: '20px' }}
              />
            )}
          </div>
        </div>
      </Card>

      {/* Modal */}
      <Modal
        title="Announcement"
        visible={showModal}
        centered
        closable={false}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => setShowModal(false)}
          >
            Got it
          </Button>,
        ]}
      >
        <p style={{ fontSize: '16px', textAlign: 'center' }}>Stay tuned!</p>
      </Modal>
    </div>
  );
};

export default PumpSniper;

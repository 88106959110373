import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Typography, Space, Button, notification } from 'antd'; // Import notification
import { FaTwitter, FaTelegram, FaGlobe } from 'react-icons/fa';
import Banner from './Banner'; // Import the Banner component
const { Text, Title } = Typography;

function NewPumpTokens() {
    const [newPumpTokens, setNewPumpTokens] = useState([]);
    const [isPolling, setIsPolling] = useState(true); // State to control polling
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        let interval;

        if (isPolling) {
            interval = setInterval(() => {
                axios
                    .get(`${baseURL}/api/new-pump-tokens`) // Replace with the correct API endpoint
                    .then((response) => {
                        const tokens = response.data.tokens || [];

                        // Sort tokens by timestamp in descending order (newest first)
                        const sortedTokens = tokens.sort((a, b) => b.timestamp - a.timestamp);

                        // Take only the first 30 tokens
                        const latestTokens = sortedTokens.slice(0, 30);

                        setNewPumpTokens(latestTokens); // Update the state with the latest 30 tokens
                    })
                    .catch((error) => {
                        console.error('Error fetching new pump tokens:', error.message);
                    });
            }, 500); // Poll every 500ms
        }

        return () => {
            if (interval) clearInterval(interval); // Cleanup the interval on component unmount
        };
    }, [isPolling]); // Effect re-runs when `isPolling` changes

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            notification.success({
                message: 'Copied to Clipboard',
                description: `Mint address ${text} has been copied to your clipboard.`,
            });
        }).catch((error) => {
            notification.error({
                message: 'Copy Failed',
                description: `Failed to copy mint address: ${error.message}`,
            });
        });
    };
    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text, token) => {
                let imageUrl = token.image;

                // Check if the image URL starts with 'https://ipfs.io/'
                if (imageUrl && imageUrl.startsWith('https://ipfs.io/')) {
                    // Replace 'https://ipfs.io/' with 'https://pump.pinata.cloud/'
                    imageUrl = imageUrl.replace('https://ipfs.io/', 'https://pump.mypinata.cloud/');
                }

                return imageUrl ? (
                    <img
                        src={imageUrl + '?img-width=200&img-dpr=1&img-onerror=redirect'}
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                    />
                ) : (
                    <Text>No image</Text>
                );
            },
        },
        {
            title: 'Token Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Text strong>{text}</Text>,
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (text) => <Text type="secondary">{text}</Text>,
        },
        {
            title: 'Mint Address',
            dataIndex: 'mintAddress',
            key: 'mintAddress',
            render: (text) => (
                <Text
                    type="secondary"
                    style={{ cursor: 'pointer', color: '#1890ff' }}
                    onClick={() => handleCopyToClipboard(text)}
                >
                    {text}
                </Text>
            ),
        },
        {
            title: 'Links',
            key: 'links',
            render: (text, token) => (
                <Space>
                    {token.twitter && (
                        <a href={token.twitter} target="_blank" rel="noopener noreferrer">
                            <FaTwitter style={{ fontSize: '24px', color: '#1DA1F2' }} />
                        </a>
                    )}
                    {token.website && (
                        <a href={token.website} target="_blank" rel="noopener noreferrer">
                            <FaGlobe style={{ fontSize: '24px', color: '#666' }} />
                        </a>
                    )}
                    {token.telegram && (
                        <a href={token.telegram} target="_blank" rel="noopener noreferrer">
                            <FaTelegram style={{ fontSize: '24px', color: '#0088cc' }} />
                        </a>
                    )}
                    {/* Add GMGN link */}
                    <a
                        href={`https://gmgn.ai/sol/token/${token.mintAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="/gmgn.png" // Add your gmgn logo image here
                            alt="GMGN"
                            style={{ width: 25, height: 25, objectFit: 'cover' }}
                        />
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <div >
            <Banner />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Title level={2} style={{ textAlign: 'center', margin: 0 }}>
                    New Pump Tokens

                </Title>

                <Space>

                    <Button type="primary" onClick={() => setIsPolling(true)} disabled={isPolling}>
                        Resume
                    </Button>
                    <Button type="default" onClick={() => setIsPolling(false)} disabled={!isPolling}>
                        Stop
                    </Button>

                </Space>
            </div>
            {newPumpTokens.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={newPumpTokens}
                    rowKey="mintAddress"
                    pagination={false}
                    bordered
                />
            ) : (
                <img src="loading-gif.gif" alt="Loading..." style={{ width: '2%', height: '2%' }} />
            )}
        </div>
    );
}

export default NewPumpTokens;


import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Card, List, Avatar, Input, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState as useStateWithCallback } from "react";
import { Modal } from "antd";
import Banner from './Banner';

const baseURL = process.env.REACT_APP_BASE_URL;
const { TextArea } = Input;

const ThreadPage = () => {
  const { id } = useParams();
  const location = useLocation(); // Access location to get wallet address
  const walletAddress = sessionStorage.getItem("walletAddress"); // Retrieve wallet address from session storage

  const [thread, setThread] = useState(null);
  const [replyContent, setReplyContent] = useState("");
  const [replyImage, setReplyImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null);

  useEffect(() => {
    fetchThread();
  }, [id]);

  const fetchThread = async () => {
    try {
      const response = await axios.get(`${baseURL}` + `/api/threads/${id}`);
      setThread(response.data);
    } catch (error) {
      console.error("Error fetching thread:", error);
      message.error("Failed to fetch thread");
    }
  };

  const handleReply = async () => {
    try {
      const formData = new FormData();
      formData.append("content", replyContent);
      if (replyImage) {
        formData.append("image", replyImage);
      }
      console.log("posting, wallet address: " + walletAddress);
      await axios.post(
        `${baseURL}` + `/api/threads/${id}/replies`,
        {
          content: replyContent,
          image: replyImage,
          creatorId: walletAddress, // Use wallet address for creatorId
        },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Reply added successfully");
      setReplyContent("");
      setReplyImage(null);
      fetchThread();
    } catch (error) {
      console.error("Error adding reply:", error);
      message.error("Please sign into your wallet to post a reply");
    }
  };

  const handleImageClick = (image) => {
    setExpandedImage(image);
    setIsModalVisible(true);
  };

  if (!thread) return <div>Loading...</div>;

  return (
    
    <div style={{ margin: "0 auto" }}>
      <Banner />
      <Card size="small"
        title={thread.title}
        extra={`Posted ${thread.createdAt}` + ` by: ${thread.creatorId}`}
        style={{ maxWidth: "500px"}} // Add maxWidth and margin styles
        

        cover={
          thread.image && (
            <img
              alt="Thread"
              src={`${baseURL}` + `/api` + `${thread.image}`}
              onClick={() => handleImageClick(thread.image)}
              style={{ maxWidth: "700px", cursor: "pointer", padding: "5px" }}
            />
          )
        }
      >
        <p>{thread.content}</p>
      </Card>
      
      <List
        itemLayout="vertical"
        dataSource={thread.replies}
        renderItem={(reply) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar>{reply.creatorId.charAt(0)}</Avatar>}
              title={`${reply.creatorId}`}
            />
            <p style={{ fontSize: "12px", color: "grey"}}>{new Date(reply._doc.createdAt).toLocaleString()}</p> 
            {reply._doc.image && (
              <img
                alt="Reply"
                src={`${baseURL}` + `/api` + `${reply._doc.image}`}
                onClick={() => handleImageClick(reply._doc.image)}
                style={{ maxWidth: "15%", cursor: "pointer" }}
              />
            )}
            
             
            <p>{reply._doc.content}</p>
          </List.Item>
        )}
      />
      
      <div style={{ marginTop: "20px" }}>
        <TextArea
          rows={4}
          value={replyContent}
          onChange={(e) => setReplyContent(e.target.value)}
          placeholder="Write your reply..."
        />
        
        <Upload
          beforeUpload={(file) => {
            const isValidType =
              file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "image/gif";
            if (!isValidType) {
              message.error("You can only upload PNG, JPEG, or GIF files!");
            } else {
              setReplyImage(file);
            }
            return isValidType || Upload.LIST_IGNORE; // Ignore invalid files
          }}
          fileList={replyImage ? [replyImage] : []} // Show the selected image
          onRemove={() => setReplyImage(null)} // Allow removing the image
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>

        <Button
          type="primary"
          onClick={handleReply}
          style={{ marginTop: "10px" }}
        >
          Add Reply
        </Button>
        
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <img
          alt="Expanded Image"
          src={`${baseURL}` + `/api` + `${expandedImage}`}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default ThreadPage;
